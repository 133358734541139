import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { graphql } from 'gatsby';
import Image from 'gatsby-image';
import PropTypes from 'prop-types';

import Layout from '../../components/Layout';
import SEO from '../../components/SEO';

const SB3000 = ({ data }) => {
  return (
    <Layout>
      <SEO title="SB3000 Note Clearance System" />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="flex flex-wrap w-full pb-8 mx-auto mb-8 border-b-2 border-transparent max-w-7xl">
        <div className="flex items-center justify-center w-full md:w-1/2">
          <div className="w-full max-w-lg px-4 mx-auto">
            <h2 className="text-sm font-bold leading-none tracking-wide uppercase text-brand-blue">
              SB-3000 3 Pocket Sorter
            </h2>
            <h3 className="mb-6 text-3xl font-bold leading-none">
              High volume with a low profile
            </h3>
            <p className="mb-4">
              The world's smallest 3 pocket sorter designed for high volume
              counting, the SB-3000 uses cutting edge twin Image Processing
              Technology to recognise banknotes and TITO tickets and
              discriminates between them. It is ideal for cash orientated venues
              and safe/count rooms with restricted space.
            </p>
            <p className="mb-4">
              With modern design, a 4″ TFT Colour LCD and user-friendly
              interface, the SB-3000 has various and useful functions to keep up
              with growing customer requirements.
            </p>
            <p className="mb-4">
              SB-3000 can also handle TITO (tickets, voucher). First, it scans
              and acknowledges gaming machine ID and amount. Second, it sends
              scanned images and analysed ID, amount and so on (customizable on
              request) to a PC for storage or send the data and images to a
              server.
            </p>
            <p className="mb-4">
              The SB-3000 includes all features of the MIB-11 note counter.
            </p>
            <Accordion allowZeroExpanded>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>Specifications</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <table>
                    <tbody>
                      <tr>
                        <td>Stacker Size</td>
                        <td>Up to 200 notes</td>
                      </tr>
                      <td>Reject Pocket</td>
                      <td>Up to 100 notes</td>
                      <tr>
                        <td>Hopper Capacity</td>
                        <td>700 notes with ability to load while running</td>
                      </tr>
                      <tr>
                        <td>Speed</td>
                        <td>Value Counting 1000 notes/min</td>
                      </tr>
                      <tr>
                        <td>Dimensions</td>
                        <td>(W) 306mm x (L) 334mm x (H) 380mm</td>
                      </tr>
                      <tr>
                        <td>Weight</td>
                        <td>18 kg</td>
                      </tr>
                    </tbody>
                  </table>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
        <div className="w-full px-4 mt-8 md:pl-8 md:mt-0 md:w-1/3">
          <div className="w-full max-w-xs px-4 mx-auto">
            <Image fluid={data.SB3000.childImageSharp.fluid} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

SB3000.propTypes = {
  data: PropTypes.object,
};

export const query = graphql`
  query SB3000Query {
    SB3000: file(relativePath: { eq: "products/sb3000/main.png" }) {
      childImageSharp {
        fluid(maxWidth: 1764) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default SB3000;
